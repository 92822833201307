import { Input } from '../components/Input'
import { useEffect, useState } from 'react'
import { useToken } from '../hooks/useToken'
import { useAuth } from '../hooks/useAuth'
import { useNavigate } from 'react-router-dom'

export function Login() {
  const [data, setData] = useState({ username: '', password: '' })
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const { login, isLoggedIn } = useAuth({ loggedInRedirect: '/graph-view' })

  function handleChange(e) {
    const { name: key, value } = e.target
    setData({ ...data, [key]: value })
  }

  async function handleLogin() {
    try {
      const res = await login(data.username, data.password)
      if (isLoggedIn()) {
        navigate('/graph-view')
      }
    } catch (error) {
      console.error({ error })
      if (error.data) {
        setError(error.data.error)
      }
    }
  }

  useEffect(() => {
    async function checkLogin() {
      if (await isLoggedIn()) {
        navigate('/graph-view')
      }
    }
    checkLogin()
  }, [isLoggedIn, navigate])

  return (
    <form className='flex flex-col h-full max-w-xs mx-auto mt-48 bg-'>
      <div className='mb-4'>
        <label htmlFor='username'>Username</label>
        <Input
          type='text'
          name='username'
          onChange={handleChange}
          value={data.username}
        />
      </div>
      <div className='mb-4'>
        <label htmlFor='username'>Password</label>
        <Input
          type='password'
          name='password'
          onChange={handleChange}
          value={data.password}
          className='form-input'
        />
      </div>
      <button
        type='button'
        onClick={handleLogin}
        className='px-8 py-4 mt-4 text-white bg-indigo-500 border rounded-md'
      >
        Login
      </button>
      {error && <div className='mt-6 text-center text-red-500'>{error}</div>}
    </form>
  )
}
