import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { zip } from 'lodash'
import { useDebouncedEffect } from '../hooks/useDebouncedEffect'

import { useAuth } from '../hooks/useAuth'

import { Controls } from '../components/Controls'
import { TheGraph } from '../components/TheGraph'
import {
  IntroBlurb,
  CumulativeForgoneValue,
  TotalForgoneValue,
} from '../components/TextContent'

export function DataViz() {
  const { makeAuthenticatedRequest, token } = useAuth({ locked: true })

  const navigate = useNavigate()

  const [yearValues, setYearValues] = useState([1874, 2022])

  // SALE DATA SCENARIO
  const [RTLValue, setRTLValue] = useState('')
  // RETURNS SCENARIO
  const [RHOValue, setRHOValue] = useState('')
  // PROPRTION SCENARIO
  const [PValue, setPValue] = useState('')

  // List of available scenarios for each select
  const [scenarios, setScenarios] = useState({ p: [], rho: [], sr: [] })

  const [data, setData] = useState()

  /**
   * When our sliders/values change, fetch the new data from the server.
   */
  useEffect(() => {
    async function fetchScenarios() {
      if (!token || token == 'false') {
        navigate('/')
      } else {
        const res = await makeAuthenticatedRequest('/fetchScenarios', {}, 'get')
        setScenarios(res.data)
        setRTLValue(res.data.sr[0])
        setRHOValue(res.data.rho[0])
        setPValue(res.data.p[0])
      }
    }

    fetchScenarios()
  }, [])

  useDebouncedEffect(
    () => {
      async function fetchData() {
        if (!token || token == 'false') {
          navigate('/')
        } else {
          const [start_year, end_year] = yearValues
          const res = await makeAuthenticatedRequest('/fetchData', {
            sale_scenario: RTLValue,
            proportion_scenario: PValue,
            returns_scenario: RHOValue,
            start_year,
            end_year,
          })
          let resData = res?.data?.data
          if (!resData) return
          const proportionOfLand = Object.values(
            resData['Proportion of land in Métis hands']
          )
          const rateOfReturnsToCapital = Object.values(
            resData['Rate of returns to capital']
          )
          const returnsToCapital = Object.values(resData['Returns to capital'])
          const returnsToLand = Object.values(resData['Returns to land'])
          const finalPerAcreValue = resData['current_per_acre_value']
          const finaltotalAcreageValue = resData['current_total_acreage_value']

          const image = resData.image || 'test-image.jpg'

          const cumulativeLandValue = zip(returnsToCapital, returnsToLand)
          const [cumulativeRTC, cumulativeRTL] =
            cumulativeLandValue[cumulativeLandValue.length - 1]
          const totalCumulative = Math.floor(cumulativeRTC + cumulativeRTL)

          setData({
            ...data,
            proportionOfLand,
            rateOfReturnsToCapital,
            returnsToCapital,
            cumulativeLandValue,
            totalCumulative,
            cumulativeRTC,
            cumulativeRTL,
            finalPerAcreValue,
            finaltotalAcreageValue,
            image,
          })
        }
      }
      fetchData()

      // // Hacky fix for data occasionally not loading into graph.
      const timer = setTimeout(() => {
        fetchData()
      }, 250)
      return () => clearTimeout(timer)
    },
    [makeAuthenticatedRequest, navigate, token, RTLValue, RHOValue, PValue],
    50
  )

  const [minYear, maxYear] = yearValues
  const minIdx = minYear - 1874
  const maxIdx = maxYear - 1874

  if (!token || token === 'false') return null

  return (
    <>
      <div className='flex'>
        {/* CONTROLS */}
        <IntroBlurb />
        {/* <HowToText /> */}
      </div>

      <div className='flex justify-between'>
        <div>
          <Controls
            scenarios={scenarios}
            yearState={[yearValues, setYearValues]}
            RTLState={[RTLValue, setRTLValue]}
            RHOState={[RHOValue, setRHOValue]}
            PState={[PValue, setPValue]}
          />
        </div>
        {/* DATA VIS */}
        {data && (
          <div className='flex flex-col w-2/3 '>
            <div className='w-full mx-auto mb-4 md:w-4/5'>
              <h3 className='text-xl font-bold '>Initial Acreage Location</h3>
            </div>
            <img
              src={`img/${data.image}`}
              className='w-full max-w-xs mx-auto mb-4 md:max-w-sm md:ml-8 lg:mx-auto xl:max-w-lg 2xl:max-w-3xl'
              alt=''
            />

            <TheGraph
              title='Annual Proportion (of Original Acreage Remaining in Métis Hands)'
              data={data.proportionOfLand
                .slice(minIdx, maxIdx + 1)
                .map((p, idx) => ({
                  year: Number(idx + minYear),
                  value: Number(p),
                }))}
              color={'yellow'}
              tickFormat={d => d.toFixed(2)}
              startYear={minYear}
              endYear={maxYear}
            />
            <TheGraph
              title='Annual Rate (of Return to Capital in %)'
              data={data.rateOfReturnsToCapital
                .slice(minIdx, maxIdx + 1)
                .map((val, idx) => ({
                  year: Number(idx + minYear),
                  value: Number(val),
                }))}
              color={'red'}
              tickFormat={d => Math.floor(d * 100).toFixed(1)}
              domainMax={RHOValue == 'School Land' ? 0.1 : null}
              dynamicYMin={RHOValue == 'Inflation (CPI)'}
              startYear={minYear}
              endYear={maxYear}
            />

            <TheGraph
              title='Annual Cumulative Forgone Land Value (x $1,000,000,000)'
              data={data.cumulativeLandValue
                .slice(minIdx, maxIdx + 1)
                .map((d, idx) => ({
                  year: idx + minYear,
                  value: [Number(d[0]), Number(d[1])],
                }))}
              tickFormat={d => {
                const num = Number(d.split(',').join('')) / 1000000000

                if (num < 0.001) return num.toFixed(4)
                if (num < 0.01) return num.toFixed(3)
                return num.toFixed(2)
              }}
              startYear={minYear}
              endYear={maxYear}
              multiValue
              numberCommas
              legend
              dynamicYMin
            />
          </div>
        )}
      </div>

      {data && (
        <>
          <TotalForgoneValue
            totalCumulative={data.totalCumulative}
            cumulativeRTC={Math.floor(data.cumulativeRTC)}
            cumulativeRTL={Math.floor(data.cumulativeRTL)}
            totalAcreageValue={Math.floor(data.finaltotalAcreageValue)}
            perAcreValue={Math.floor(data.finalPerAcreValue)}
          />
        </>
      )}
    </>
  )
}
