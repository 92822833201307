import { capitalize, startCase, uniqueId } from 'lodash'
import { RTCDescription, RTLDescription, PDescription } from './TextContent'

export const DropdownControls = ({ RTLState, RHOState, PState, scenarios }) => {
  const [RTL, setRTL] = RTLState
  const [RHO, setRHO] = RHOState
  const [PValue, setP] = PState

  return (
    <div>
      <div className='flex flex-col max-w-lg'>
        <label className='mb-2 font-bold'>Initial Acreage Location</label>
        <select
          className='p-4 mb-3 border appearance-none bg-slate-100 border-slate-500'
          value={RTL}
          onChange={e => setRTL(e.target.value)}
        >
          <DropdownOptions scenarios={scenarios.sr} />
        </select>
        <RTLDescription />
      </div>

      <div className='flex flex-col max-w-lg'>
        <label className='mb-2 font-bold'>Proportion</label>
        <select
          className='p-4 mb-3 border appearance-none bg-slate-100 border-slate-500'
          value={PValue}
          onChange={e => setP(e.target.value)}
        >
          <DropdownOptions scenarios={scenarios.p} />
        </select>
        <PDescription />
      </div>

      <div className='flex flex-col max-w-lg'>
        <label className='mb-2 font-bold'>Rate</label>
        <select
          className='p-4 mb-3 border appearance-none bg-slate-100 border-slate-500'
          value={RHO}
          onChange={e => setRHO(e.target.value)}
        >
          <DropdownOptions scenarios={scenarios.rho} />
        </select>
        <RTCDescription />
      </div>
    </div>
  )
}

const DropdownOptions = ({ scenarios }) => {
  return (
    <>
      {scenarios.map((s, idx) => {
        const label = cleanLabel(s)

        const styles = `p-2 mt-4 appearance-none bg-slate-5 ${
          idx != scenarios.length - 1 ? 'mt-4' : ''
        }`
        return (
          <option key={uniqueId(s)} className={styles} value={s}>
            {label}
          </option>
        )
      })}
    </>
  )
}

// Remove underscores and capitalize letters (without destroying symbols through lodash)
function cleanLabel(name) {
  let label = name.replaceAll('_', ' ').replace(/\w+/g, startCase)

  if (label === 'S 31 Register') {
    return 'S31 Register'
  }
  return label
}
