import React, { useEffect } from 'react'
import { useToken } from './useToken'
import { useNavigate } from 'react-router-dom'
import { API_ROOT } from '../config'
import axios from 'axios'

let AuthContext = React.createContext(null)

export function AuthProvider({ children }) {
  const { token, setToken, clearToken } = useToken()
  const navigate = useNavigate()

  async function login(username, password) {
    try {
      const res = await axios.post(API_ROOT + '/login', { username, password })
      const { access_token } = res.data
      setToken(access_token)
      return res
    } catch (err) {
      throw err.response
    }
  }

  const logout = () => setToken(false)

  async function isLoggedIn() {
    if (!token || token === 'false') return false

    const res = await axios.get(API_ROOT + '/validateAuth', {
      headers: { Authorization: 'Bearer ' + token },
      // withCredentials: true,
    })

    return res.data.isAuthenticated
  }

  async function makeAuthenticatedRequest(
    url,
    data,
    method = 'post',
    privateRedirect = '/'
  ) {
    try {
      const res = await axios({
        url: API_ROOT + url,
        method,
        data,
        headers: { Authorization: 'Bearer ' + token },
        // withCredentials: false,
      })

      return res
    } catch (error) {
      if (error.response) {
        const { status } = error.response
        if (status === 401 || status === 422) {
          clearToken()
          navigate(privateRedirect)
        }
      }
    }
  }

  let value = { token, login, logout, isLoggedIn, makeAuthenticatedRequest }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = ({ locked = false, privateRedirect = '/' } = {}) => {
  const navigate = useNavigate()
  const { isLoggedIn } = React.useContext(AuthContext)

  useEffect(() => {
    const isAuthed = isLoggedIn()
    if (locked && !isAuthed) {
      navigate(privateRedirect)
    }
  }, [isLoggedIn, locked, navigate, privateRedirect])

  return React.useContext(AuthContext)
}
