import { numberWithCommas, nearestMillion } from '../lib'

export const IntroBlurb = () => (
  <div className='w-full mt-16 mb-16'>
    <h1 className='mb-8 text-3xl text-black sm:text-center'>
      MMF Scenario Valuation Tool
    </h1>
    <div className='block '>
      <div className='max-w-3xl mx-auto prose'>
        <p className='text-[17px] mt-0 mb-4 pr-8'>
          This tool is intended to help the MMF negotiation team understand how
          key variables affect the estimate of the cumulative forgone land value
          for the Métis as a result of the Crown’s failure to diligently
          implement section 31. The menus below contain several scenarios for
          each of the variables that determine this value.
        </p>
        <p className='text-[17px] mb-4'>
          The cumulative forgone land value depends on{' '}
          <b className='underline'>five</b> key variables:
        </p>
      </div>

      <ul className='prose text-[15px] max-w-3xl mx-auto mb-10'>
        <li className=''>
          <h3 className='mt-0 mb-0 text-lg font-bold text-chaos-emerald'>
            Starting Year
          </h3>
          <p>
            The year by which land should have been fully granted. This is 1874,
            meaning our estimates cover every year from 1874 to 2022.
          </p>
        </li>
        <li className=''>
          <h3 className='mt-0 mb-0 text-[17px] font-bold text-chaos-emerald'>
            Initial Acreage
          </h3>{' '}
          <p>
            The initial size of section 31 lands that should have been granted.
            As per the Manitoba Act, 1870, the initial size of section 31 lands
            is 1.4 million acres.
          </p>
        </li>
        <li className=''>
          <h3 className='mt-0 mb-0 text-[17px] font-bold text-chaos-emerald'>
            Initial Acreage Location
          </h3>
          <p>
            The initial location of section 31 lands that should have been
            granted. Each scenario represents a different location and, as a
            result, different returns to land (i.e., how much the Métis would
            have earned, per acre, each year). Returns to land include
            productive payments and one-time purchase and sale payments.
          </p>
        </li>
        <li className=''>
          <h3 className='mt-0 mb-0 text-[17px] font-bold text-chaos-emerald'>
            Proportion
          </h3>{' '}
          <p>
            The proportion of the original acreage that would have remained in
            Métis hands in each year. The proportion is influenced by land
            management. That is, it increases with land purchases and decreases
            with land sales.
          </p>
        </li>
        <li className=''>
          <h3 className='mt-0 mb-0 text-[17px] font-bold text-chaos-emerald'>
            Rate
          </h3>
          <p>
            These scenarios represent different rates of return that the Métis
            would have earned each year by investing funds in the “bank
            account.” Funds flow into and out of the “bank account” from
            productive payments and one-time purchase and sale payments.
          </p>
        </li>
      </ul>

      <HowToText />
    </div>
  </div>
)

export const HowToText = () => (
  <div className='prose text-[15px] max-w-3xl mx-auto'>
    <p className='text-lg font-bold '>
      To calculate cumulative forgone land value
    </p>
    <ol className='p-0 prose list-none'>
      <li className='flex items-start justify-start mb-5'>
        <span className='text-white flex items-center justify-center mr-2 w-6 h-6 p-2 rounded-[50%] bg-chaos-emerald '>
          1
        </span>
        <div>
          Combine{' '}
          <em className='not-italic font-bold text-chaos-emerald'>
            Initial Acreage
          </em>{' '}
          and{' '}
          <em className='not-italic font-bold text-chaos-emerald'>
            Proportion
          </em>{' '}
          to determine how much land would have been held, purchased or sold
          each year.
        </div>
      </li>
      <li className='flex items-start justify-start mb-5'>
        <span className='text-white flex items-center justify-center mr-2 w-6 h-6 p-2 rounded-[50%] bg-chaos-emerald '>
          2
        </span>
        <div>
          Calculate the balance in the Métis “bank account” each year by
          combining the productive payments and sale payments in the{' '}
          <em className='not-italic font-bold text-chaos-emerald'>
            Initial Acreage Location
          </em>{' '}
          scenario with the quantities of land held, purchased, or sold each
          year and adding that to the previous year’s “bank account” balance
        </div>
      </li>
      <li className='flex items-start justify-start mb-5'>
        <span className='text-white flex items-center justify-center mr-2 w-6 h-6 p-2 rounded-[50%] bg-chaos-emerald '>
          3
        </span>
        <div>
          Calculate{' '}
          <em className='not-italic font-bold text-chaos-emerald'>
            Return to Capital
          </em>{' '}
          to the funds in the Métis “bank account” each year
        </div>
      </li>
      <li className='flex items-start justify-start mb-5'>
        <span className='text-white flex items-center justify-center mr-2 w-6 h-6 p-2 rounded-[50%] bg-chaos-emerald '>
          4
        </span>
        <div>Sum returns to land and returns to capital from each year</div>
      </li>
    </ol>
  </div>
)

export const RTLDescription = () => (
  <div className='mb-4 prose'>
    <p className='text-[15px] text-gray-600'>
      The map shows the initial acreage location within Manitoba's 1870 borders.
      The black dotted line indicates the area for which we collected data.
    </p>

    <p className='text-[15px] text-gray-600'>
      Each scenario represents a different location for the initial acreage of
      section 31 lands. Each scenario is associated with a different
      “interpretation” of diligent implementation. Different locations mean
      different per-acre returns to land each year.
    </p>
  </div>
)

export const PDescription = () => (
  <div className='mb-4 prose'>
    <p className='text-[15px] text-gray-600'>
      Each proportion scenario represents a different Métis land management
      strategy.
    </p>
  </div>
)

export const RTCDescription = () => (
  <div className='mb-4 prose'>
    <p className='text-[15px] text-gray-600'>
      Each scenario represents a different proxy for the 1-year rate of return
      that the Métis would have earned on funds in their “bank account.”
    </p>
  </div>
)

export const CumulativeForgoneValue = ({
  totalCumulative = 0,
  cumulativeRTL = 0,
  cumulativeRTC = 0,
  perAcreValue = 0,
  totalAcreageValue = 0,
}) => {
  // const formatNumber = (n) => nearestMillion(Math.floor(n))
  return (
    <div className='max-w-3xl mx-auto mt-8 prose'>
      <h4 className='mb-2 font-bold'>Cumulative Forgone Land Value</h4>
      <p>
        Under the selected set of scenarios, the estimate of cumulative forgone
        land value would be{' '}
        <span className='text-2xl font-bold'>
          ${numberWithCommas(nearestMillion(totalCumulative))}
        </span>
        .
      </p>
      <p>
        Of this,{' '}
        <span className='text-lg font-bold'>
          ${numberWithCommas(nearestMillion(cumulativeRTL))}
        </span>{' '}
        would come from cumulative returns to land and{' '}
        <span className='text-lg font-bold'>
          ${numberWithCommas(nearestMillion(cumulativeRTC))}
        </span>{' '}
        from cumulative returns to capital. The entire initial acreage would
        sell for an estimated{' '}
        <span className='text-lg font-bold'>
          ${numberWithCommas(nearestMillion(totalAcreageValue))}
        </span>{' '}
        in 2022 if it were kept until that date, or{' '}
        <span className='text-lg font-bold'>
          ${numberWithCommas(perAcreValue)}
        </span>{' '}
        per acre.
      </p>
    </div>
  )
}

export const TotalForgoneValue = ({
  totalCumulative = 0,
  cumulativeRTC,
  cumulativeRTL,
  totalAcreageValue,
  perAcreValue,
}) => {
  // const prosperity = nearestMillion(totalCumulative * 1.57)

  // NATIONHOOD VALUES
  // Previously these were multiplied against the total cumulative value
  const NATIONHOOD_VALUE = 7368000000
  const cultural = nearestMillion(NATIONHOOD_VALUE * 1.54)
  const community = nearestMillion(NATIONHOOD_VALUE * 1.38)
  const governance = nearestMillion(NATIONHOOD_VALUE * 0.86)

  const landValue = nearestMillion(totalCumulative * 1)

  const totalForgone = cultural + community + landValue + governance
  const additionalNationhoodValue = nearestMillion(
    totalForgone - totalCumulative
  )

  return (
    <div className='max-w-3xl mx-auto mt-16 prose'>
      <h4 className='mb-2 font-bold'>Total Forgone Land Value</h4>
      <p>
        The estimate of total forgone value for the selected set of scenarios is
        <span className='text-lg font-bold'>
          {' '}
          ${numberWithCommas(totalForgone)}
        </span>
        . This is made up of{' '}
        <span className='text-lg font-bold'>
          ${numberWithCommas(nearestMillion(totalCumulative))}
        </span>{' '}
        in land value and{' '}
        <span className='text-lg font-bold'>
          ${numberWithCommas(additionalNationhoodValue)}
        </span>{' '}
        in additional nationhood value.
      </p>

      <p>
        This table breaks down the total forgone value into its components and
        subcomponents.
      </p>

      <table className='max-w-xl mx-auto sm:text-lg'>
        <thead>
          <tr>
            <th className='text-lg font-bold'>
              Total Forgone Value <br />
              Components and <br />
              Subcomponents
            </th>
          </tr>
        </thead>
        <tbody>
          {/* <tr>
            <td>Prosperity</td>
            <td>&nbsp;</td>
            <td className='text-right'>${numberWithCommas(prosperity)}</td>
          </tr> */}

          <tr>
            <td>Cultural Identity & Pride</td>
            <td>&nbsp;</td>
            <td className='text-right'>${numberWithCommas(cultural)}</td>
          </tr>

          <tr>
            <td>Community & Kinship</td>
            <td>&nbsp;</td>
            <td className='text-right'>${numberWithCommas(community)}</td>
          </tr>

          <tr>
            <td>Land Value</td>
          </tr>

          <tr>
            <td className='pl-8'>Cumulative Returns to Land</td>
            <td className='text-right'>
              ${numberWithCommas(nearestMillion(cumulativeRTL))}
            </td>
          </tr>

          <tr>
            <td className='pb-1 pl-8'>Cumulative Returns to Capital</td>
            <td className='pb-2 text-right border-b-2 border-gray-600'>
              ${numberWithCommas(nearestMillion(cumulativeRTC))}
            </td>
          </tr>

          <tr>
            <td>Cumulative Land Value</td>
            <td>&nbsp;</td>
            <td className='text-right'>
              ${numberWithCommas(nearestMillion(totalCumulative))}
            </td>
          </tr>

          <tr>
            <td className='pb-1'>Governance</td>
            <td className='pb-1'>&nbsp;</td>
            <td className='pb-2 text-right border-b-2 border-gray-600'>
              ${numberWithCommas(nearestMillion(governance))}
            </td>
          </tr>

          <tr className='font-bold '>
            <td className='pt-5 pb-1'>Total Forgone Value</td>
            <td className='pb-1'>&nbsp;</td>
            <td className='pb-1 font-bold text-right'>
              ${numberWithCommas(totalForgone)}
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        If the entire intial acreage was kept until 2022, it would sell for an
        estimated{' '}
        <span className='text-lg font-bold'>
          ${numberWithCommas(nearestMillion(totalAcreageValue))}
        </span>
        , or{' '}
        <span className='text-lg font-bold'>
          ${numberWithCommas(perAcreValue)}
        </span>{' '}
        per acre.
      </p>
    </div>
  )
}
