import React from 'react'
import { DropdownControls } from './DropdownControls'
import { YearSlider } from './YearSlider'

export const Controls = ({
  RTLState,
  RHOState,
  PState,
  yearState,
  scenarios,
}) => {
  return (
    <div className='sticky top-0 w-full pt-10 prose'>
      <YearSlider yearState={yearState} />
      <DropdownControls
        scenarios={scenarios}
        RHOState={RHOState}
        PState={PState}
        RTLState={RTLState}
      />
    </div>
  )
}
