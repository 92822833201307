import { Range, getTrackBackground } from 'react-range'

const MIN = 1874
const MAX = 2022

export const YearSlider = ({ yearState }) => {
  const [yearValues, setYearValues] = yearState
  const [minYear, maxYear] = yearValues

  function handleChange(values) {
    let [min, max] = values

    // Find out which value has changed
    let changedValue = 'min'
    if (max != maxYear) {
      changedValue = 'max'
    }

    // Make sure we always have at least one year range.
    if (max - min <= 1) {
      if (changedValue == 'min') {
        min = Math.max(MIN, min - 2)
      } else {
        max = Math.min(MAX, max + 2)
      }
    }

    setYearValues([min, max])
  }

  return (
    <>
      <label className='font-bold '>Date Range</label>
      <Range
        values={yearValues}
        step={1}
        min={MIN}
        max={MAX}
        // rtl={rtl}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '95%',
              margin: '0 auto',
              marginTop: '2rem',
              marginBottom: '1.5rem',
              paddingLeft: '.45rem',
              paddingRight: '.45rem',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: yearValues,
                  colors: ['#ccc', '#2F7B80', '#ccc'],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ index, props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '36px',
              width: '36px',
              borderRadius: '4px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
              outline: 'none',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-28px',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '14px',
                fontFamily: 'Roboto,sans-serif',
                padding: '1px 4px',
                borderRadius: '4px',
                backgroundColor: '#2F7B80',
              }}
            >
              {yearValues[index]}
            </div>
            <div
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? '#2F7B80' : '#CCC',
              }}
            />
          </div>
        )}
      />
    </>
  )
}
