import React, { useEffect, useState, useLayoutEffect } from 'react'
import { CSVLink } from 'react-csv'
import html2canvas from 'html2canvas'
import * as d3 from 'd3'
import { numberWithCommas } from '../lib'

export const TheGraph = ({
  title,
  data,
  color = 'yellow',
  domainMax = null,
  yAxisPrepend = '',
  yAxisAppend = '',
  numberCommas = false,
  multiValue = false,
  tickFormat = null,
  fixedTickValues,
  legend = false,
  dynamicYMin = false,
  yLabel = '',
  startYear = 1874,
  endYear = 2022,
}) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const screenshotRef = React.useRef()
  const graphRef = React.useRef()

  const handleScreenshot = async () => {
    const graph = screenshotRef.current
    const canvas = await html2canvas(graph)

    const data = canvas.toDataURL('image/jpg')
    const link = document.createElement('a')

    if (typeof link.download === 'string') {
      link.href = data
      link.download = 'mmf-valuation-tool-export.jpg'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(data)
    }
  }

  useEffect(() => {
    const xScale = d3
      .scaleLinear()
      .domain([startYear, endYear])
      .range([0, width])

    let yMin, yMax, yScale, yDomain
    if (multiValue) {
      yDomain = d3.extent(
        [].concat(
          data.map(d => d.value[0]),
          data.map(d => d.value[1])
        )
      )
      yMin = yDomain[0]
      yMax = yDomain[0]
    } else {
      yMin = d3.min(data, d => d.value)
      yMax = domainMax || d3.max(data, d => d.value)
      if (dynamicYMin) {
        yDomain = [yMin, yMax]
      } else {
        yDomain = [0, yMax]
      }
    }

    if (!dynamicYMin) {
      yMin = 0
    }

    yScale = d3.scaleLinear().domain(yDomain).range([height, 0])

    const xAxisTickValues = generateTickValues(startYear, endYear)

    const xAxis = d3
      .axisBottom()
      .scale(xScale)
      .tickValues(xAxisTickValues)
      .tickFormat((d, i) => {
        return d
      })

    let numYTicks = 10

    const yAxis = d3
      .axisLeft()
      .scale(yScale)
      .ticks(numYTicks)
      .tickFormat((d, i) => {
        if (d == 0) return d

        if (numberCommas) d = numberWithCommas(d)

        if (tickFormat) {
          return yAxisPrepend + tickFormat(d) + yAxisAppend
        }
        return yAxisPrepend + d + yAxisAppend
      })

    if (fixedTickValues) {
      const min = yDomain[0]
      const max = yDomain[1]
      const step = (Math.abs(min) + max) / 10

      const ticks = []
      for (let i = 0; i < 10; i++) {
        const tick = min + step * i
        ticks.push(tick)
      }
      yAxis.tickValues(ticks)
    }

    const line = d3
      .line()
      .x(d => {
        return xScale(d.year)
      })
      .y(d => {
        return yScale(Array.isArray(d.value) ? d.value[0] : d.value)
      })

    let line2
    if (multiValue) {
      line2 = d3
        .line()
        .x(d => xScale(d.year))
        .y(d => yScale(d.value[1]))
    }

    const graph = d3.select(graphRef.current)
    graph.selectAll('svg').remove()

    const svg = graph.append('svg')
    svg.attr('width', width).attr('height', height).attr('overflow', 'visible')

    // Create line chart
    svg
      .append('path')
      .datum(data)
      .attr('class', 'line')
      .attr('d', line)
      .classed('line-yellow', color == 'yellow')
      .classed('line-red', color == 'red')
      .classed('line-blue', color === 'blue')

    if (multiValue) {
      svg
        .append('path')
        .datum(data)
        .attr('class', 'line')
        .attr('d', line2)
        .classed('line-blue', true)
    }

    if (legend) {
      const xStart = 50
      const yStart = 50

      // if (color == 'yellow') {
      svg
        .append('circle')
        .attr('cx', xStart)
        .attr('cy', yStart)
        .attr('r', 6)
        .style('fill', 'rgb(193, 177, 48)')
      svg
        .append('text')
        .attr('x', xStart + 20)
        .attr('y', yStart + 5)
        .text('Returns to Capital')

        .style('font-size', '15px')
        .attr('alignment-baseline', 'middle')
      // } else {
      svg
        .append('circle')
        .attr('cx', xStart)
        .attr('cy', yStart + 25)
        .attr('r', 6)
        .style('fill', '#339CA2')
      svg
        .append('text')
        .attr('x', xStart + 20)
        .attr('y', yStart + 30)
        .text('Returns to Land')
        .style('font-size', '15px')
        .attr('alignment-baseline', 'middle')
      // }
    }

    if (yLabel) {
      svg
        .append('text')
        .attr('class', 'y label')
        .attr('text-anchor', 'end')
        .attr('x', 250)
        .attr('y', 35)
        .attr('dy', '.75em')
        .attr('transform', 'rotate(90)')
        .text(yLabel)
    }

    // create Axes
    svg
      .append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(0 ${height + 10})`)
      .call(xAxis)

    svg.append('g').attr('class', 'axis').call(yAxis)
  }, [data, startYear, endYear])

  useLayoutEffect(() => {
    setWidth(screenshotRef.current.offsetWidth)
    setHeight(screenshotRef.current.offsetHeight)
  }, [data, startYear, endYear])

  return (
    <div className='w-full mx-auto mb-24 md:w-4/5'>
      <div ref={screenshotRef} className='w-full mb-4 h-96'>
        <h3 className='mb-4 text-xl font-bold '>{title}</h3>

        <div ref={graphRef} />
      </div>
      {/* 
      <div className='flex gap-4 mt-10'>
        <button
          type='button'
          onClick={handleScreenshot}
          className='w-1/2 px-5 py-3 text-white bg-slate-500'
        >
          Screenshot Graph
        </button>
        <CSVLink
          // data={csvData}
          data={'words'}
          target='_blank'
          filename={'mmf-valuation-export.csv'}
          className='w-1/2 px-5 py-3 text-center transition-colors border border-gray-400 hover:bg-slate-500 hover:text-white'
        >
          Export Table
        </CSVLink>
      </div> */}
    </div>
  )
}

function generateTickValues(startYear, endYear) {
  let numXTicks = 8
  if (endYear - startYear < 8) {
    numXTicks = endYear - startYear
  }

  if (numXTicks <= 2) {
    return [startYear, endYear]
  }

  const diff = endYear - startYear
  const increment = diff / (numXTicks - 1)
  return [
    startYear,
    ...Array(numXTicks)
      .fill('')
      .map((_, idx) => Math.floor(startYear + increment * idx)),
    endYear,
  ]
}
