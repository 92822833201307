import { useState, useEffect } from 'react'

export const useLocalStorage = (key, initial) => {
  const item = JSON.parse(window.localStorage.getItem(key))
  const [value, setValue] = useState(item || initial)

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }, [value, key, initial])

  const removeItem = () => window.localStorage.removeItem(key)

  return [value, setValue, removeItem]
}
