import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ga4 from 'react-ga4'

const isProduction = process.env.NODE_ENV === 'production'

function initAnalytics(trackingId) {
  ga4.initialize(trackingId, {
    testMode: !isProduction,
  })
}

/*
 * Not required if using Googles Enhanced Measurement
 */
function sendPageView(path) {
  ga4.send({ hitType: 'pageView', page: path })
}

export function useAnalytics(trackingId, sendManualPageViews = false) {
  const location = useLocation()

  useEffect(() => {
    initAnalytics(trackingId)
  }, [])

  useEffect(() => {
    if (sendManualPageViews) {
      const path = location.pathname + location.search
      sendPageView(path)
    }
  }, [location])
}
