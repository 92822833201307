import { Routes, Route } from 'react-router-dom'
import { DataViz } from './pages/DataViz'
import { Login } from './pages/Login'

import { useAnalytics } from './hooks/useAnalytics'
import { GOOGLE_TRACKING_ID } from './config'

function App() {
  useAnalytics(GOOGLE_TRACKING_ID)

  return (
    <div className='app bg-gray-50 '>
      <main className='container min-h-screen p-12 mx-auto'>
        <Routes>
          <Route path='/' element={<Login />}></Route>
          <Route path='/graph-view' element={<DataViz />}></Route>
        </Routes>
      </main>
    </div>
  )
}

export default App
