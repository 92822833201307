const ROOT_DOMAIN = 'mmfvaluationtool.com'

export const API_ROOT =
  process.env.NODE_ENV === 'production'
    ? `https://api.${ROOT_DOMAIN}`
    : `http://localhost:5000`

export const GOOGLE_TRACKING_ID = 'G-3TXZCQNW3F'

export const SCENARIOS = {
  scenarioA: {
    name: 'Scenario A',
    returnsToCapital: 'canadian-bonds-rate',
    landbase1895: 0.9,
    landbase1920: 0.7,
    landbase1945: 0.5,
    landbase1970: 0.6,
    landbase1995: 0.2,
  },
  scenarioB: {
    name: 'Scenario B',
    returnsToCapital: 'canadian-bonds-rate',
    landbase1895: 0.9,
    landbase1920: 0.7,
    landbase1945: 0.5,
    landbase1970: 0.6,
    landbase1995: 0.2,
  },
  scenarioC: {
    name: 'Scenario B',
    returnsToCapital: 'canadian-bonds-rate',
    landbase1895: 0.9,
    landbase1920: 0.7,
    landbase1945: 0.5,
    landbase1970: 0.6,
    landbase1995: 0.2,
  },
  scenarioD: {
    name: 'Scenario B',
    returnsToCapital: 'canadian-bonds-rate',
    landbase1895: 0.9,
    landbase1920: 0.7,
    landbase1945: 0.5,
    landbase1970: 0.6,
    landbase1995: 0.2,
  },
  scenarioE: {
    name: 'Scenario B',
    returnsToCapital: 'canadian-bonds-rate',
    landbase1895: 0.9,
    landbase1920: 0.7,
    landbase1945: 0.5,
    landbase1970: 0.6,
    landbase1995: 0.2,
  },
}
